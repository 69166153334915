import React from "react";
import { Link } from "gatsby";

import App from "../components/App";
import SEO from "../components/seo";
import Emoji from "../components/emoji";

const NotFoundPage = () => (
  <App>
    <SEO title="404: Not found" />
    <p>
      Ooops... You seem lost, maybe you took a wrong door?{" "}
      <Emoji label="Door" emoji="🚪" /> Let's{" "}
      <Link to="/">
        <Emoji label="Pointing back" emoji="👈" /> go back
      </Link>
    </p>
  </App>
);

export default NotFoundPage;
